import { takeLatest, all, call, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls, variables } from '../lib/urls';
import Api from '../lib/request-service';
import { notification } from 'antd';

export default function* ForgotPasswordSaga() {
    yield takeLatest(types.FORGOT_PASSWORD_REQUEST, ForgotPasswordSagaApi);
}
function* ForgotPasswordSagaApi(params) {
    let param = params.payload;
    let history = params.history;

    try {
        const response = yield Api.post(`${api_urls.FORGOT_PASSWORD}`, param, true);
        if (response.status === true) {
            yield put({ type: types.FORGOT_PASSWORD_SUCCESS, payload: response });
            // notification success
            // history.push('/forgot-reset-email',);
            history.push({ pathname: '/forgot-reset-email', state: { email: param.email } });
            notification.success({
                placement: 'topRight',
                message: 'Reset password link has been sent to your provided email. Please check inbox'
            });
        } else {
            yield put({ type: types.FORGOT_PASSWORD_FAILURE, error: response });
            // notification error res.message
            notification.error({
                placement: 'topRight',
                message: response.message
            });
        }
    } catch (error) {
        yield put({ type: types.FORGOT_PASSWORD_FAILURE, error: error });
        // not error error.message
        notification.error({
            placement: 'topRight',
            // message: err.message,
            message: error
        });
    }
}
