const setToken = (userToken)=>{
    return localStorage.setItem('token',userToken);
};
const removeToken = (userToken)=>{
    return localStorage.removeItem('token');
};
const setTime= (time)=>{
    return localStorage.setItem('time',time);
};
const removeTime = (time)=>{
    return localStorage.removeItem('time');
};

const  getTime = ()=>{
    return  (localStorage.getItem('time') !== "undefined" || localStorage.getItem('time') !== null)?JSON.parse(localStorage.getItem('time')):undefined
};
const removeUser = ()=>{
    return localStorage.removeItem('user');
};
const  getToken = ()=>{
    return  (localStorage.getItem('token') !== "undefined"|| localStorage.getItem('token') !== null)?localStorage.getItem('token'):null
};
const setUser = (userData)=>{
    return localStorage.setItem('user', JSON.stringify(userData));
};
const  getUser = ()=>{
    return  (localStorage.getItem('user') !== "undefined" && localStorage.getItem('user') !== null)?JSON.parse(localStorage.getItem('user')):null
};
const setOrg = (orgData)=>{
    return localStorage.setItem('org', JSON.stringify(orgData));
};
const  getOrg = ()=>{
    return  (localStorage.getItem('org') !== "undefined" && localStorage.getItem('org') !== null)?JSON.parse(localStorage.getItem('org')):null
};
const removeOrg = ()=>{
    return localStorage.removeItem('org');
};
const getOrgId = ()=>{
    return  (localStorage.getItem('user') !== "undefined" && localStorage.getItem('user') !== null)?JSON.parse(localStorage.getItem('user')).org:null
};
const setOrgId = (id)=>{
    return  (localStorage.getItem('user') !== "undefined" && localStorage.getItem('user') !== null)?localStorage.setItem('user',JSON.stringify({...JSON.parse(localStorage.getItem('user')),org:id})):null
};
const getUserId = ()=>{
    return  (localStorage.getItem('user') !== "undefined" && localStorage.getItem('user') !== null)?JSON.parse(localStorage.getItem('user'))._id:null
};

const setTempCampId= (tempCampId)=>{
    return localStorage.setItem('tempCampId',tempCampId);
};
const  getTempCampId= ()=>{
    return  (localStorage.getItem('tempCampId') !== "undefined"|| localStorage.getItem('tempCampId') !== null)?localStorage.getItem('tempCampId'):null
};
const removeTempCampId = ()=> {
    return localStorage.removeItem('tempCampId');
};
const removePersistData = ()=> {
    return localStorage.removeItem('persist:root');
};

export default {setToken, getToken, removeToken, setUser, getUser, removeUser, getOrgId, getUserId, setOrg, getOrg, removeOrg, setOrgId, getTime, setTime, removeTime, setTempCampId, getTempCampId, removeTempCampId, removePersistData};
