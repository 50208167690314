import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../../config';
import navigation from '../../../../menu-items';
import DEMO from '../../../../store/constant';
import { Home2 } from 'iconsax-react';
import { useSelector } from 'react-redux';

const Breadcrumb = (props) => {
    const [main, setMain] = useState();
    const [item, setItem] = useState();
    let { get_campaign } = useSelector(state => state);

    const getCollapse = useCallback((item) => {
        if (item.children) {
            item.children.filter((collapse) => {
                if (collapse.type && collapse.type === 'collapse') {
                    getCollapse(collapse);
                }
                else if (collapse.type && collapse.type === 'item') {
                    if (document.location.pathname === config.basename + collapse.url) {
                        setItem(collapse);
                        setMain(item);
                    }
                }
                return false;
            });
        }
    }, []);

    useEffect(() => {
        navigation.items.map((item, index) => {
            if (item.type && item.type === 'group') {
                getCollapse(item);
            }
            return false;
        });
    }, [props, getCollapse]);

    let main_, item_;
    let breadcrumb = '';
    let title = 'Fanweave';
    if (main && main.type === 'collapse') {
        main_ = (<li className="breadcrumb-item">
            <a href={DEMO.BLANK_LINK}>{main.title}</a>
        </li>);
    }
    if (item && item.type === 'item') {
        title = item.title;
        item_ = (<li className="breadcrumb-item">
            <a href={DEMO.BLANK_LINK}>{title}</a>
        </li>);
        if (item.breadcrumbs !== false) {
            breadcrumb = (<div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="page-header-title">
                                <h5 className="m-b-10">{title}</h5>
                            </div>
                            <ul className="breadcrumb align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/overview">
                                        <Home2 size="14" variant="Broken" />
                                        {/*<i className="feather icon-home"/>*/}
                                    </Link>
                                </li>
                                {main_}
                                {item_}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>);
        }
    }
    // if(get_campaign?.data){
    //     document.title = title + ` | ${get_campaign?.data?.name[0].toUpperCase() + get_campaign?.data?.name.slice(1)}`;
        
    //     if(get_campaign?.data?.template?.icon){
    //         var link = document.querySelector("link[rel~='icon']");
    //         if (!link) {
    //             link = document.createElement('link');
    //             link.rel = 'icon';
    //             document.head.appendChild(link);
    //         }
    //         link.href = get_campaign?.data?.template?.icon;
    //     } else {
    //         var link = document.querySelector("link[rel~='icon']");
    //         if (!link) {
    //             link = document.createElement('link');
    //             link.rel = 'icon';
    //             document.head.appendChild(link);
    //         }
    //         link.href = get_campaign?.data?.template?.logo;
    //     }
    // } else {
    //     document.title = title
    // }
    return <>{breadcrumb}</>;
};
export default Breadcrumb;
