import types from '../types/types';
const initialState = {
    user: {},
    error: '',
    data: null,
    isLoading: false
};
const signInDomainReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIGN_IN_REQUEST_DOMAIN:
            return {
                ...state
            };
        case types.SIGN_IN_SUCCESS_DOMAIN:
            return {
                ...state,
                status: 200,
                message: 'SUCCESS',
                error: null,
                data: action.payload.data,
                loading: false
            };
        case types.SIGN_IN_FAILURE_DOMAIN:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false
            };
        case types.SIGN_IN_UPDATE_DOMAIN:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        case types.SIGN_IN_UPDATE_DOMAIN_DATA:
            return {
                ...state,
                data: { ...state.data, ...action.payload }
            };
        case types.SIGN_IN_UPDATE:
            return {
                ...state,
                status: null,
                message: null,
                error: null,
                data: null,
                loading: false
            };
        case types.UPDATE_USER_AFTER_EDIT:
            return {
                ...state,
                status: 200,
                message: 'SUCCESSFULLY UPDATED',
                error: null,
                data: { ...state.data, ...action.payload},
                loading: false
            }
        default: {
            return state;
        }
    }
};
export default signInDomainReducer;
