import * as React from 'react';
import { Suspense, useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import subDomainRoutes from '../subDomainRoutes';
import 'antd/dist/antd.css';
import { useDispatch } from 'react-redux';
import { getCampaign } from '../store/actions/getCampainActions';
import Api from '../store/lib/request-service';
import { api_urls } from '../store/lib/urls';
import types from '../store/types/types';
import CONSTANTS from '../constants/constants';
import Breadcrumb from './layout/AdminLayout/Breadcrumb';
import getTemplateByName from '../store/sagas/templatesSaga';
import { getTemplate } from '../store/actions/templateActions';

const HOST = process.env.REACT_APP_API_SUB_DOMAIN;
const App = () => {
    let dispatch = useDispatch();
    let history = useHistory();
    let pK
    let template = null
    if (!!window.location.search && window.location.search.includes('pK='))
        pK = window.location.search.slice(window.location.search?.indexOf('=') + 1, window.location.search.length);
    else if(!!window.location.search &&  window.location.search.includes('template=true')){
        if(window.location.search.includes('public'))
            template={custom:true,public:true}
        else if(window.location.search.includes('private'))
            template={custom:true,private:true}
    }
    useEffect(() => {
        subDomain();
    }, []);

    const checkPendingCampaign = async (subDomain, pK) => {
        try {
            let response = await Api.get(api_urls.GET_CAMPAIGN_VIEW + `/${subDomain}`, true, pK)
            if (response?.status) {
                dispatch({ type: types.GET_CAMPAIGN_SUCCESS, payload: response?.data })
                let title = 'Fanweave';
                if (response?.data) {
                    document.title = title + ` | ${response?.data?.name[0].toUpperCase() + response?.data?.name.slice(1)}`;
    
                    if (response?.data?.template?.icon) {
                        var link = document.querySelector("link[rel~='icon']");
                        if (!link) {
                            link = document.createElement('link');
                            link.rel = 'icon';
                            document.head.appendChild(link);
                        }
                        link.href = response?.data?.template?.icon;
                    } else {
                        var link = document.querySelector("link[rel~='icon']");
                        if (!link) {
                            link = document.createElement('link');
                            link.rel = 'icon';
                            document.head.appendChild(link);
                        }
                        link.href = response?.data?.template?.logo;
                    }
                } else {
                    document.title = title;
                }
            }
        } catch (e) {
            console.log('error in pending campaign', e)
        }
    }

    const subDomain = () => {
        let host = window.location.host;
        let hostArray = HOST.split(',');
        let parts = host.split('.');
        let expDomain = null;
        if (parts?.length > 1) expDomain = parts.splice(0, 1)[0];
        let subDomain = null;
        let find = hostArray.findIndex((as) => as === expDomain);
        if (find === -1) {
            subDomain = expDomain;
        } else if (parts[0] === expDomain) {
            subDomain = expDomain;
        }
        if (!!subDomain) {
            if (subDomain != CONSTANTS.DEMO_TEMPLATES.GENERIC && subDomain != CONSTANTS.DEMO_TEMPLATES.LEADERBOARD && subDomain != CONSTANTS.DEMO_TEMPLATES.MUSIC_CLIP && subDomain != CONSTANTS.DEMO_TEMPLATES.VIDEO && subDomain != CONSTANTS.DEMO_TEMPLATES.HASHTAG && subDomain != CONSTANTS.DEMO_TEMPLATES.UPLOAD_FILE && subDomain != CONSTANTS.DEMO_TEMPLATES.EVENT_TEMP) {
                if(template?.custom){
                    dispatch(getTemplate({template_name: subDomain }))
                }else if (!pK) {
                    dispatch(getCampaign(subDomain));
                }else {
                    checkPendingCampaign(subDomain, pK);
                }
            } else {
                history.push({
                    pathname: '/',
                    state: {
                        subDomain: subDomain
                    }
                })
            }
        }
    };

    const data = localStorage.getItem('token');
    const location = useLocation();

    const getRedirection = () => {
        let host = window.location.host;
        let hostArray = HOST.split(',');
        let parts = host.split('.');
        if (parts.length < 2)
            return false
        let expDomain = parts.splice(0, 1)[0];
        let subDomain = null;
        if (hostArray.findIndex((as) => as === expDomain) === -1) {
            subDomain = expDomain;
        } else if (parts[0] === expDomain) {
            subDomain = expDomain;
        }
        if ((!data || data) && !!subDomain && typeof subDomain != null) {
            return true;
        } else {
            return false;
        }
    };

    return (<>
        <ScrollToTop>
            <Breadcrumb />
            <Suspense fallback={<Loader />}>
                {getRedirection() &&
                    <Switch>
                        {/*this will be the login check for specific campaign*/}
                        <Route path={subDomainRoutes.map((x) => x.path)}>
                            <Switch location={location} key={location.pathname}>
                                {subDomainRoutes.map((route, index) => {
                                    return route.component ? (
                                        <Route key={index} path={route.path} exact={route.exact}
                                            render={(props) => <route.component {...props} />} />) : null;
                                })}
                            </Switch>
                        </Route>
                    </Switch>
                }
            </Suspense>
        </ScrollToTop>
        <div className='backdrop' />
    </>);
};
export default App;
