// import commonFunctions from '../helpers/commonFunctions';

import session from "./session";
import { resetSignInDomain } from '../actions/user.actions';

const HOST = process.env.REACT_APP_API_URL;

class Api {
    static headers(multipart = false) {
        if (multipart) {
            return {
                'Content-Type': 'multipart/form-data'
            };
        } else {
            return {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            };
        }
    }

    static get(route, sendAuthToken = false, token = null) {
        return this.xhr(route, null, 'GET', sendAuthToken, false, token);
    }

    static put(route, params, sendAuthToken = false) {
        return this.xhr(route, params, 'PUT', sendAuthToken, false);
    }

    static post(route, params, sendAuthToken = false, multipart = false, token = null) {
        return this.xhr(route, params, 'POST', sendAuthToken, multipart,token);
    }

    static delete(route, params, sendAuthToken = false) {
        return this.xhr(route, params, 'DELETE', sendAuthToken, false);
    }

    static async logout() {
        session.removeToken();
        session.removeUser();
        session.removePersistData()
        setTimeout(() => {
            window.history.replaceState({},document.title);
            window.location.reload();
        }, 2000)

    }

    static async xhr(route, params, verb, sendAuthToken, multipart, customToken) {
        let token = await session.getToken();
        const url = `${HOST}${route}`;
        let options = Object.assign({ method: verb }, params ? { body: multipart ? params : JSON.stringify(params) } : null);

        options.headers = Api.headers(multipart);
        if (token && sendAuthToken) {
            options.headers.Authorization = 'Bearer ' + token;
        } else if(customToken){
            options.headers.Authorization = 'Bearer ' + customToken;
        }

        return fetch(url, options)
            .then((resp) => {
                let json = resp.json();
                if (resp.ok) {
                    return json;
                } else {
                    if (resp.status == 401) {
                        Api.logout();
                    }
                    return json;
                }
                return json.then((err) => {
                    if (resp.status == 401) {
                        Api.logout();
                    }
                    console.log('Err::', err);
                    throw err;
                });
            })
            .then((res) => {
                return res;
            });
    }
}
export default Api;
