import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';

export default function* getTemplateByName() {
    yield takeLatest(types.GET_TEMPLATE_REQUEST, getTemplateByNameApi);
}
function* getTemplateByNameApi(params) {
    const name =  params?.payload?.template_name;
    try {
        const response = yield Api.get( api_urls.GET_CUSTOM_TEMPLATE_BY_NAME+`?name=${name}`);
        if (response.status) {
            yield put({ type: types.GET_TEMPLATE_SUCCESS, payload: response?.data });
            // notification success
        } else {
            yield put({ type: types.GET_TEMPLATE_FAILURE, error: response });
            // notification error res.message
        }
    } catch (error) {
        yield put({ type: types.GET_TEMPLATE_FAILURE, error: error });
        // notification error error.message
    }
}
