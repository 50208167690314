// import { fork } from 'child_process';
import { all, fork } from 'redux-saga/effects';
import SignInSaga from './user.sagas';

import ForgotPasswordSaga from './forgotPasswordSaga';
import ResetPasswordSaga from './resetPasswordSagas';
import ChangePasswordSaga from './changePasswordSaga';
import LogoutSaga from './logoutSaga';
import OTPValidationSaga from './otpValidationSaga';
import getCampaignByDomain from './getCampainByDomain';
import DomainSignUpSaga from './domainAuthSaga';
import SignInDomainSaga from './signInDomainSaga';
import getCampaignById from './getCampainById'
import getTemplateByName from './templatesSaga'
export default function* rootSaga() {
    yield all([
        fork(SignInSaga),
        fork(ForgotPasswordSaga),
        fork(ResetPasswordSaga),
        fork(ChangePasswordSaga),
        fork(LogoutSaga),
        fork(OTPValidationSaga),
        fork(getCampaignByDomain),
        fork(DomainSignUpSaga),
        fork(SignInDomainSaga),
        fork(getCampaignById),
        fork(getTemplateByName),
    ]);
}
