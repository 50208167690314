import { Share } from 'iconsax-react';
import ReactGA from 'react-ga4';
export const view = (data)=> {
    /* Typically the object that was interacted with (e.g. 'Video')
    category: string;
    The type of interaction (e.g. 'play') 
    action: string;
    Useful for categorizing events (e.g. 'Fall Campaign') 
    label: string;
    A numeric value associated with the event (e.g. 42)
    value: number;
   */
  ReactGA.ga('send',{
    'hitType': 'pageview',
    'page': '/Main',
    'title':data.name,
    'sessionControl': 'start'
  })
  //ReactGA.send({ hitType:"pageview", page: '/', title:data.name });
  //   ReactGA.event({
  //       category: 'campaign',
  //       action: 'view',
  //       label:data.campaign_name,
  //       value:1
  //     });
}

export const share = (data)=>{
  ReactGA.event({
        category: 'social',
        action: 'share',
        label:data.platform,
        value:5
      });
}