const types = {
    //create campaign
    CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
    CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
    CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',
    CREATE_CAMPAIGN_UPDATE: 'CREATE_CAMPAIGN_UPDATE',
    UPDATE_CAMPAIGN_STATES: 'UPDATE_CAMPAIGN_STATES',
    UPDATE_CAMPAIGN_STATES_REWARDS: 'UPDATE_CAMPAIGN_STATES_REWARDS',
    EMPTY_CAMPAIGN_STATES: 'EMPTY_CAMPAIGN_STATES',

    //edit campaign
    EDIT_CAMPAIGN_REQUEST: 'EDIT_CAMPAIGN_REQUEST',
    EDIT_CAMPAIGN_SUCCESS: 'EDIT_CAMPAIGN_SUCCESS',
    EDIT_CAMPAIGN_FAILURE: 'EDIT_CAMPAIGN_FAILURE',
    EDIT_CAMPAIGN_UPDATE: 'EDIT_CAMPAIGN_UPDATE',
    EDIT_CAMPAIGN_STATES: 'EDIT_CAMPAIGN_STATES',
    EDIT_CAMPAIGN_STATES_REWARDS: 'EDIT_CAMPAIGN_STATES_REWARDS',
    EMPTY_EDIT_CAMPAIGN_STATES: 'EMPTY_EDIT_CAMPAIGN_STATES',

    //get edit campaign by domain name
    GET_CAMPAIGN_EDIT_REQUEST: 'GET_CAMPAIGN_EDIT_REQUEST',
    GET_CAMPAIGN_EDIT_SUCCESS: 'GET_CAMPAIGN_EDIT_SUCCESS',
    GET_CAMPAIGN_EDIT_FAILURE: 'GET_CAMPAIGN_EDIT_FAILURE',
    GET_CAMPAIGN_EDIT_UPDATE: 'GET_CAMPAIGN_EDIT_UPDATE',
    GET_CAMPAIGN_EDIT_STATES: 'GET_CAMPAIGN_EDIT_STATES',
    GET_CAMPAIGN_EDIT_STATES_REWARDS: 'GET_CAMPAIGN_EDIT_STATES_REWARDS',
    EMPTY_GET_CAMPAIGN_EDIT_STATES: 'EMPTY_GET_CAMPAIGN_EDIT_STATES',

    //get campaign by domain name
    GET_CAMPAIGN_REQUEST: 'GET_CAMPAIGN_REQUEST',
    GET_CAMPAIGN_SUCCESS: 'GET_CAMPAIGN_SUCCESS',
    GET_CAMPAIGN_FAILURE: 'GET_CAMPAIGN_FAILURE',
    GET_CAMPAIGN_UPDATE: 'GET_CAMPAIGN_UPDATE',
    GET_CAMPAIGN_STATES: 'GET_CAMPAIGN_STATES',
    GET_CAMPAIGN_STATES_REWARDS: 'GET_CAMPAIGN_STATES_REWARDS',
    EMPTY_GET_CAMPAIGN_STATES: 'EMPTY_GET_CAMPAIGN_STATES',
    // Template
    GET_TEMPLATE_REQUEST: 'GET_TEMPLATE_REQUEST',
    GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
    GET_TEMPLATE_FAILURE: 'GET_TEMPLATE_FAILURE',
    // User
    SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
    SIGN_IN_UPDATE: 'SIGN_IN_UPDATE',
    SIGN_IN_STATES: 'SIGN_IN_STATES',
    // Forget Password
    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_UPDATE: 'FORGOT_PASSWORD_UPDATE',
    FORGOT_PASSWORD_STATES: 'FORGOT_PASSWORD_STATES',
    // Reset Password
    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_UPDATE: 'RESET_PASSWORD_UPDATE',
    RESET_PASSWORD_STATES: 'RESET_PASSWORD_STATES',
    // Change Password
    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_UPDATE: 'CHANGE_PASSWORD_UPDATE',
    CHANGE_PASSWORD_STATES: 'CHANGE_PASSWORD_STATES',
    // Add Client
    ADD_CLIENT_REQUEST: 'ADD_CLIENT_REQUEST',
    ADD_CLIENT_SUCCESS: 'ADD_CLIENT_SUCCESS',
    ADD_CLIENT_FAILURE: 'ADD_CLIENT_FAILURE',
    ADD_CLIENT_UPDATE: 'ADD_CLIENT_UPDATE',
    ADD_CLIENT_STATES: 'ADD_CLIENT_STATES',
    // Add Admin
    ADD_ADMIN_REQUEST: 'ADD_ADMIN_REQUEST',
    ADD_ADMIN_SUCCESS: 'ADD_ADMIN_SUCCESS',
    ADD_ADMIN_FAILURE: 'ADD_ADMIN_FAILURE',
    ADD_ADMIN_UPDATE: 'ADD_ADMIN_UPDATE',
    ADD_ADMIN_STATES: 'ADD_ADMIN_STATES',
    // Get All Clients
    GET_ALL_CLIENTS_REQUEST: 'GET_ALL_CLIENTS_REQUEST',
    GET_ALL_CLIENTS_SUCCESS: 'GET_ALL_CLIENTS_SUCCESS',
    GET_ALL_CLIENTS_FAILURE: 'GET_ALL_CLIENTS_FAILURE',
    GET_ALL_CLIENTS_UPDATE: 'GET_ALL_CLIENTS_UPDATE',
    GET_ALL_CLIENTS_STATES: 'GET_ALL_CLIENTS_STATES',
    // Get All Fans
    GET_ALL_FANS_REQUEST: 'GET_ALL_FANS_REQUEST',
    GET_ALL_FANS_SUCCESS: 'GET_ALL_FANS_SUCCESS',
    GET_ALL_FANS_FAILURE: 'GET_ALL_FANS_FAILURE',
    GET_ALL_FANS_UPDATE: 'GET_ALL_FANS_UPDATE',
    GET_ALL_FANS_STATES: 'GET_ALL_FANS_STATES',
    // Get All Admins
    GET_ALL_ADMINS_REQUEST: 'GET_ALL_ADMINS_REQUEST',
    GET_ALL_ADMINS_SUCCESS: 'GET_ALL_ADMINS_SUCCESS',
    GET_ALL_ADMINS_FAILURE: 'GET_ALL_ADMINS_FAILURE',
    GET_ALL_ADMINS_UPDATE: 'GET_ALL_ADMINS_UPDATE',
    GET_ALL_ADMINS_STATES: 'GET_ALL_ADMINS_STATES',
    // Delete Admin
    DEL_ADMIN_REQUEST: 'DEL_ADMIN_REQUEST',
    DEL_ADMIN_SUCCESS: 'DEL_ADMIN_SUCCESS',
    DEL_ADMIN_FAILURE: 'DEL_ADMIN_FAILURE',
    DEL_ADMIN_UPDATE: 'DEL_ADMIN_UPDATE',
    DEL_ADMIN_STATES: 'DEL_ADMIN_STATES',
    // Logout
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
    LOGOUT_UPDATE: 'LOGOUT_UPDATE',
    LOGOUT_STATES: 'LOGOUT_STATES',
    // Get User by Id
    GET_USER_BY_ID_REQUEST: 'GET_USER_BY_ID_REQUEST',
    GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
    GET_USER_BY_ID_FAILURE: 'GET_USER_BY_ID_FAILURE',
    GET_USER_BY_ID_UPDATE: 'GET_USER_BY_ID_UPDATE',
    GET_USER_BY_ID_STATES: 'GET_USER_BY_ID_STATES',
    // View User by Id
    VIEW_USER_BY_ID_REQUEST: 'VIEW_USER_BY_ID_REQUEST',
    VIEW_USER_BY_ID_SUCCESS: 'VIEW_USER_BY_ID_SUCCESS',
    VIEW_USER_BY_ID_FAILURE: 'VIEW_USER_BY_ID_FAILURE',
    VIEW_USER_BY_ID_UPDATE: 'VIEW_USER_BY_ID_UPDATE',
    VIEW_USER_BY_ID_STATES: 'VIEW_USER_BY_ID_STATES',
    // Update Admin
    UPDATE_ADMIN_REQUEST: 'UPDATE_ADMIN_REQUEST',
    UPDATE_ADMIN_SUCCESS: 'UPDATE_ADMIN_SUCCESS',
    UPDATE_ADMIN_FAILURE: 'UPDATE_ADMIN_FAILURE',
    UPDATE_ADMIN_UPDATE: 'UPDATE_ADMIN_UPDATE',
    UPDATE_ADMIN_STATES: 'UPDATE_ADMIN_STATES',
    // Update User
    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
    UPDATE_USER_UPDATE: 'UPDATE_USER_UPDATE',
    UPDATE_USER_STATES: 'UPDATE_USER_STATES',
    // Add Role
    ADD_ADMIN_ROLE_REQUEST: 'ADD_ADMIN_ROLE_REQUEST',
    ADD_ADMIN_ROLE_SUCCESS: 'ADD_ADMIN_ROLE_SUCCESS',
    ADD_ADMIN_ROLE_FAILURE: 'ADD_ADMIN_ROLE_FAILURE',
    ADD_ADMIN_ROLE_UPDATE: 'ADD_ADMIN_ROLE_UPDATE',
    ADD_ADMIN_ROLE_STATES: 'ADD_ADMIN_ROLE_STATES',
    // Admin Update Role
    ADMIN_UPDATE_ROLE_REQUEST: 'ADMIN_UPDATE_ROLE_REQUEST',
    ADMIN_UPDATE_ROLE_SUCCESS: 'ADMIN_UPDATE_ROLE_SUCCESS',
    ADMIN_UPDATE_ROLE_FAILURE: 'ADMIN_UPDATE_ROLE_FAILURE',
    ADMIN_UPDATE_ROLE_UPDATE: 'ADMIN_UPDATE_ROLE_UPDATE',
    ADMIN_UPDATE_ROLE_STATES: 'ADMIN_UPDATE_ROLE_STATES',
    // Resend Forgot Password Email
    RESEND_EMAIL_FORGOT_PASSWORD_REQUEST: 'RESEND_EMAIL_FORGOT_PASSWORD_REQUEST',
    RESEND_EMAIL_FORGOT_PASSWORD_SUCCESS: 'RESEND_EMAIL_FORGOT_PASSWORD_SUCCESS',
    RESEND_EMAIL_FORGOT_PASSWORD_FAILURE: 'RESEND_EMAIL_FORGOT_PASSWORD_FAILURE',
    RESEND_EMAIL_FORGOT_PASSWORD_UPDATE: 'RESEND_EMAIL_FORGOT_PASSWORD_UPDATE',
    RESEND_EMAIL_FORGOT_PASSWORD_STATES: 'RESEND_EMAIL_FORGOT_PASSWORD_STATES',
    // OTP Validation
    OTP_VALIDATION_REQUEST: 'OTP_VALIDATION_REQUEST',
    OTP_VALIDATION_SUCCESS: 'OTP_VALIDATION_SUCCESS',
    OTP_VALIDATION_FAILURE: 'OTP_VALIDATION_FAILURE',
    OTP_VALIDATION_UPDATE: 'OTP_VALIDATION_UPDATE',
    OTP_VALIDATION_STATES: 'OTP_VALIDATION_STATES',
    // User Domain
    SIGN_IN_REQUEST_DOMAIN: 'SIGN_IN_REQUEST_DOMAIN',
    SIGN_IN_SUCCESS_DOMAIN: 'SIGN_IN_SUCCESS_DOMAIN',
    SIGN_IN_FAILURE_DOMAIN: 'SIGN_IN_FAILURE_DOMAIN',
    SIGN_IN_UPDATE_DOMAIN: 'SIGN_IN_UPDATE_DOMAIN',
    SIGN_IN_UPDATE_DOMAIN_DATA: 'SIGN_IN_UPDATE_DOMAIN_DATA',
    SIGN_IN_STATES_DOMAIN: 'SIGN_IN_STATES_DOMAIN',

    SIGN_UP_REQUEST_DOMAIN: 'SIGN_UP_REQUEST_DOMAIN',
    SIGN_UP_SUCCESS_DOMAIN: 'SIGN_UP_SUCCESS_DOMAIN',
    SIGN_UP_FAILURE_DOMAIN: 'SIGN_UP_FAILURE_DOMAIN',
    SIGN_UP_UPDATE_DOMAIN: 'SIGN_UP_UPDATE_DOMAIN',
    SIGN_UP_STATES_DOMAIN: 'SIGN_UP_STATES_DOMAIN',

    // Verify Email
    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',
    VERIFY_EMAIL_UPDATE: 'VERIFY_EMAIL_UPDATE',
    VERIFY_EMAIL_STATES: 'VERIFY_EMAIL_STATES',
    // Resend Verification Email
    RESEND_VERIFY_EMAIL_REQUEST: 'RESEND_VERIFY_EMAIL_REQUEST',
    RESEND_VERIFY_EMAIL_SUCCESS: 'RESEND_VERIFY_EMAIL_SUCCESS',
    RESEND_VERIFY_EMAIL_FAILURE: 'RESEND_VERIFY_EMAIL_FAILURE',
    RESEND_VERIFY_EMAIL_UPDATE: 'RESEND_VERIFY_EMAIL_UPDATE',
    RESEND_VERIFY_EMAIL_STATES: 'RESEND_VERIFY_EMAIL_STATES',

    // Edit User
    UPDATE_USER_AFTER_EDIT: 'UPDATE_USER_AFTER_EDIT',
    
    // Contact Form
    CONTACT_FORM: 'CONTACT_FORM',
    // Show Notification bubble
    SHOW_BUBBLE: 'SHOW_BUBBLE',
    UPDATE_BUBBLE: 'UPDATE_BUBBLE',
    EMPTY_BUBBLE: 'EMPTY_BUBBLE',
};

export default types;
