import { Home2, Personalcard, EmojiHappy, Card, Setting5, StatusUp, Element4, People, MessageQuestion, Graph } from 'iconsax-react'

const chartData = {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'overview',
                    title: 'Overview',
                    type: 'item',
                    url: '/overview',
                    icon: <Home2 size="18"  variant="Broken"/>,
                    breadcrumbs: true,
                },
                {
                    id: 'clients',
                    title: 'Clients',
                    type: 'collapse',
                    icon: <Personalcard size="18" variant="Broken"/>,
                    /*badge: {
                        title: 'New',
                        type: 'badge-warning'
                    },*/
                    children: [
                        {
                            id: 'all-clients',
                            title: 'Clients List',
                            type: 'item',
                            url: '/clients/all-clients',
                            permissions: ['add_client', 'edit_client', 'block_client']
                        },
                        {
                            id: 'add-client',
                            title: 'Add Client',
                            type: 'item',
                            url: '/clients/add-client',
                            breadcrumbs: true,
                            permissions: ['add_client']
                        },
                        {
                            id: 'client-profile',
                            title: 'Profile',
                            type: 'item',
                            icon: 'feather icon-file-text',
                            url: '/clients/client-profile',
                            classes: 'd-none',
                        },
                    ],
                    permissions: ['add_client', 'edit_client', 'block_client']
                },
                {
                    id: 'fans',
                    title: 'Fans',
                    type: 'item',
                    url: '/fans/all-fans',
                    icon: <EmojiHappy size="18" variant="Broken"/>,
                    /*badge: {
                        title: 'New',
                        type: 'badge-warning'
                    },*/
                    /*children: [
                        {
                            id: 'all-fans',
                            title: 'Fans List',
                            type: 'item',
                            url: '/fans/all-fans'
                        },
                    ]*/
                },
                {
                    id: 'campaigns',
                    title: 'Campaigns',
                    type: 'collapse',
                    icon: <Element4 size="18" variant="Broken"/>,
                    /*badge: {
                        title: 'New',
                        type: 'badge-warning'
                    },*/
                    children: [
                        {
                            id: 'create-new',
                            title: 'Create Campaign',
                            type: 'item',
                            url: '/campaigns/create-new',
                            breadcrumbs: true,
                            permissions: ['create_campaign']
                        },
                        {
                            id: 'campaign-hub',
                            title: 'Campaign Hub',
                            type: 'item',
                            url: '/campaigns/campaign-hub',
                            breadcrumbs: true
                        },
                        {
                            id: 'campaign-dashboard',
                            title: 'Campaign Dashboard',
                            type: 'item',
                            url: '/campaigns/campaign-dashboard',
                            classes: 'd-none',
                        },
                        {
                            id: 'pending-campaigns',
                            title: 'Pending Campaigns',
                            type: 'item',
                            url: '/campaigns/pending-campaigns',
                            breadcrumbs: true
                        },
                        {
                            id: 'campaign-drafts',
                            title: 'Draft Campaigns',
                            type: 'item',
                            url: '/campaigns/drafts',
                            breadcrumbs: true
                        },
                    ],

                },
                {
                    id: 'pricing',
                    title: 'Pricing Plans',
                    type: 'collapse',
                    icon: <Card size="18" variant="Broken"/>,
                    /*badge: {
                        title: 'New',
                        type: 'badge-warning'
                    },*/
                    children: [
                        /*{
                            id: 'add-new-plan',
                            title: 'Add New Plan',
                            type: 'item',
                            url: '/pricing/create-new',
                            breadcrumbs: true
                        },*/
                        {
                            id: 'view-all-plans',
                            title: 'View All Plans',
                            type: 'item',
                            url: '/pricing/view-all',
                            breadcrumbs: true
                        },
                        {
                            id: 'coupons',
                            title: 'Coupons',
                            type: 'item',
                            url: '/coupons'
                        },
                    ],

                },
                {
                    id: 'affiliate-marketing',
                    title: 'Affiliate Marketing',
                    type: 'collapse',
                    icon: <StatusUp size="18" variant="Broken"/>,
                    children: [
                        {
                            id: 'affiliate-programs',
                            title: 'Affiliate Programs',
                            type: 'item',
                            url: '/affiliate-programs',
                        },
                        {
                            id: 'affiliate-transactions',
                            title: 'Affiliate Transactions',
                            type: 'item',
                            url: '/affiliate-transactions',
                        },
                    ],
                },
                {
                    id: 'notifications',
                    title: 'Notifications',
                    type: 'item',
                    url: '/notifications',
                    icon: 'feather icon-bell',
                    classes: 'd-none',
                },
                {
                    id: 'reports',
                    title: 'Reports',
                    type: 'item',
                    url: '/reports',
                    icon: <Graph size="18" variant="Broken"/>,
                    breadcrumbs: true,
                },
                {
                    id: 'admin',
                    title: 'User Management',
                    type: 'collapse',
                    icon: <People size="18" variant="Broken"/>,
                    children: [
                        {
                            id: 'add-admin',
                            title: 'Add User',
                            type: 'item',
                            url: '/admin/add-new-admin',
                            breadcrumbs: true,
                            permissions: ['add_admin', 'edit_admin', 'delete_admin']
                        },
                        {
                            id: 'admins',
                            title: 'All Users',
                            type: 'item',
                            url: '/admin',
                            breadcrumbs: true,
                            permissions: ['add_admin', 'edit_admin', 'delete_admin']
                        },
                        {
                            id: 'roles-permissions',
                            title: 'Roles & Permissions',
                            type: 'item',
                            url: '/roles/roles-permissions',
                            breadcrumbs: true
                        },
                        {
                            id: 'add-admin',
                            title: 'Add User',
                            type: 'item',
                            url: '/admin/add-new-admin',
                            show: false
                        },
                        {
                            id: 'edit-admin',
                            title: 'Edit User',
                            type: 'item',
                            url: '/admin/edit-admin',
                            show: false,
                            classes: 'd-none',
                        },
                        // {
                        //     id: 'Edit-admin',
                        //     title: 'Edit Admin',
                        //     type: 'item',
                        //     url: '/admin/edit-admin',
                        //     breadcrumbs: true
                        // },
                    ],
                    permissions: ['add_admin', 'edit_admin', 'delete_admin']
                },


                /*{
                    id: 'roles-permissions',
                    title: 'Roles & Permissions',
                    type: 'collapse',
                    icon: 'feather icon-layout',
                    children: [
                        {
                            id: 'roles-permissions',
                            title: 'Roles & Permissions',
                            type: 'item',
                            url: '/roles/roles-permissions',
                            breadcrumbs: true
                        },
                        {
                            id: 'add-new-role',
                            title: 'Add New Role',
                            type: 'item',
                            url: '/roles/add-new-role',
                            breadcrumbs: true
                        },
                        ]
                },*/
                {
                    id: 'system-config',
                    title: 'System Configuration',
                    type: 'item',
                    url: '/config',
                    icon: <Setting5 size="18" variant="Broken"/>,
                    breadcrumbs: true,
                },

                {
                    id: 'support',
                    title: 'Support',
                    type: 'collapse',
                    icon: <MessageQuestion size="18" variant="Broken"/>,
                    children: [
                        {
                            id: 'contact',
                            title: 'Contact',
                            type: 'item',
                            url: '/contact'
                        },
                        {
                            id: 'terms-conditions',
                            title: 'Terms & Conditions',
                            type: 'item',
                            url: '/terms-conditions'
                        },
                        {
                            id: 'privacy-policy',
                            title: 'Privacy Policy',
                            type: 'item',
                            url: '/privacy-policy'
                        },
                        {
                            id: 'cookies-policy',
                            title: 'Cookies Policy',
                            type: 'item',
                            url: '/cookies-policy'
                        },
                        {
                            id: 'my-profile',
                            title: 'Profile',
                            type: 'item',
                            url: '/profile',
                            classes: 'd-none',
                        },
                    ]
                },

                {
                    id: 'change-password',
                    title: 'Change Password',
                    type: 'item',
                    icon: 'feather icon-layout',
                    url: '/change-password',
                    show: false
                },
            ]
        },


    ]
};
export default chartData;
