import { takeLatest, put } from 'redux-saga/effects'
import types from '../types/types';
import {api_urls} from '../lib/urls';
import session from '../lib/session'
import Api from '../lib/request-service';
import { notification } from 'antd';
import {signin} from '../../analytics/auth'

export default function* SignInDomainSaga() {
    yield takeLatest(types.SIGN_IN_REQUEST_DOMAIN, SignInDomainSagaApi);
}
function* SignInDomainSagaApi(params) {

    let param = params.payload;
    let hide = params.hide;
    try {
        const response = yield Api.post(`${api_urls.DOMAIN_USER_SIGN_IN}`, param, true);
        if (response.status === true) {
            yield put({type: types.SIGN_IN_SUCCESS_DOMAIN, payload: response});
            session.setToken(response?.data?.token);
            session.setUser(response?.data?.user);
            if(params?.analytics && response?.data?.user) signin({user_id:response?.data?.user?._id})
            // redirect
            // notification success
            notification.success({
                placement: "topRight",
                message: response.message,
                duration: '2'
            });
            hide();
        } else if (response.code === 422) { 
            let resend = { email: param.email, campaign: param.campaign, domain: params.domain, campaign_owner: param.campaign_owner }
            const verify = yield Api.post(api_urls.RESEND_VERIFY_EMAIL, resend, true);
            if (verify.status === true) {
                yield put({ type: types.SIGN_IN_FAILURE_DOMAIN, error: response });
                notification.error({
                    placement: "topRight",
                    message: response.message + ' A new verification link has been generated and sent to your email.',
                    duration: '10'
                });
            } else {
                yield put({ type: types.SIGN_IN_FAILURE_DOMAIN, error: response });
                notification.error({
                    placement: "topRight",
                    message: verify.message,
                    duration: '2'
                });
            }
        } else {
            yield put({type: types.SIGN_IN_FAILURE_DOMAIN, error: response});
            // not error res.message
            notification.error({
                placement: "topRight",
                message: response.message,
                duration: '2'
            });
        }
    } catch (error) {
        yield put({type: types.SIGN_IN_FAILURE_DOMAIN, error: error});
        // not error error.message
        notification.error({
            placement: "topRight",
            // message: err.message,
            message: "Wrong Password or Email",
            maxCount: '1',
            duration: '1'
        });
    }
}
