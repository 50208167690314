import { takeLatest, all, call, put } from 'redux-saga/effects'
import { SIGN_IN, FORGET_PASSWORD } from '../types/user.type'
import types from '../types/types';
import {api_urls, variables} from '../lib/urls';
import session from '../lib/session'
import { doSignIn, doForgetPassword } from '../service/user.service'
import { defaultActionSuccess,
defaultActionFailure,
defaultLoadingAction
} from "../app.actions";
import Api from '../lib/request-service';
import { SIGN_IN_API } from '../api';
import { notification } from 'antd';

export default function* SignInSaga() {
    yield takeLatest(types.SIGN_IN_REQUEST, SignInSagaApi);
}
function* SignInSagaApi(params) {

    let param = params.payload;
    let history = params.history;

    try {
        const response = yield Api.post(`${api_urls.SIGN_IN}`, param,true);
        if (response.status === true) {
            yield put({type: types.SIGN_IN_SUCCESS, payload: response});
            session.setToken(response?.data?.token)
            session.setUser(response?.data?.user)
            // redirect
            // notification success
            history.push('/overview');
            notification.success({
                placement: "topRight",
                message: response.message,
                duration: '1'
            });
        } else {
            yield put({type: types.SIGN_IN_FAILURE, error: response});
            // not error res.message
            notification.error({
                placement: "topRight",
                message: response.message,
                duration: '1'
            });
        }
    } catch (error) {
        yield put({type: types.SIGN_IN_FAILURE, error: error});
        // not error error.message
        notification.error({
            placement: "topRight",
            // message: err.message,
            message: "Wrong Password or Email",
            maxCount: '1',
            duration: '1'
        });
    }
}