import types from '../types/types';
export const getTemplate = (params) => {
    return {
        type: types.GET_TEMPLATE_REQUEST,
        payload: params
    };
};

export const templateGetSuccess = () => {
    return {
        type: types.GET_TEMPLATE_SUCCESS,
    };
};







