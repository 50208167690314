import ReactGA from 'react-ga4'

export const signin = (data)=>{
 /* Typically the object that was interacted with (e.g. 'Video')
    category: string;
    The type of interaction (e.g. 'play') 
    action: string;
    Useful for categorizing events (e.g. 'Fall Campaign') 
    label: string;
    A numeric value associated with the event (e.g. 42)
    value: number;
   */
    
    ReactGA.event({
        category: 'campaign',
        action: 'sign-in',
        label:getSubDomain(),
        value:3
      });
      ReactGA.ga('set', 'userId', data.user_id);
}
export const signup = (data)=>{
 /* Typically the object that was interacted with (e.g. 'Video')
    category: string;
    The type of interaction (e.g. 'play') 
    action: string;
    Useful for categorizing events (e.g. 'Fall Campaign') 
    label: string;
    A numeric value associated with the event (e.g. 42)
    value: number;
   */
    ReactGA.event({
        category: 'campaign',
        action: 'sign-up',
        label:getSubDomain(),
        value:2
      });
}
export const signout = (data)=>{
    /* Typically the object that was interacted with (e.g. 'Video')
       category: string;
       The type of interaction (e.g. 'play') 
       action: string;
       Useful for categorizing events (e.g. 'Fall Campaign') 
       label: string;
       A numeric value associated with the event (e.g. 42)
       value: number;
      */
       ReactGA.event({
           category: 'campaign',
           action: 'sign-out',
           label:getSubDomain(),
           value:4
         });
   }
const getSubDomain = () => {
    let host = window.location.host;
    let parts = host.split('.');
    let expDomain = null;
    if(parts?.length > 1) return expDomain = parts.splice(0, 1)[0];
    else
    return null
    }