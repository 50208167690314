import { takeLatest, all, call, put } from 'redux-saga/effects';
import { SIGN_IN, FORGET_PASSWORD } from '../types/user.type';
import types from '../types/types';
import { api_urls, variables } from '../lib/urls';
import session from '../lib/session';
import { doSignIn, doForgetPassword } from '../service/user.service';
import { defaultActionSuccess, defaultActionFailure, defaultLoadingAction } from '../app.actions';
import Api from '../lib/request-service';
import { SIGN_IN_API } from '../api';
import { notification } from 'antd';
import '../../assets/css/custom.css';
import {signup} from '../../analytics/auth'

export default function* DomainSignUpSaga() {
    yield takeLatest(types.SIGN_UP_REQUEST_DOMAIN, DomainSignUpSagaApi);
}
function* DomainSignUpSagaApi(params) {
    let param = params.payload;
    let hide = params.hide;
    try {
        const response = yield Api.post(`${api_urls.DOMAIN_USER_SIGN_UP}`, param);
        if (response.status === true) {
            yield put({ type: types.SIGN_UP_SUCCESS_DOMAIN, payload: response });
            session.setToken(response?.data?.token);
            session.setUser(response?.data?.user);
            if(params?.analytics) signup({user_id:response?.data?.user?._id})
            notification.success({
                placement: 'topRight',
                message: response.message,
                duration: '10',
            });
            hide();
        } else {
            yield put({ type: types.SIGN_UP_FAILURE_DOMAIN, error: response });
            // not error res.message
            notification.error({
                placement: 'topRight',
                message: response.message,
                duration: '10',
            });
        }
    } catch (error) {
        yield put({ type: types.SIGN_UP_FAILURE_DOMAIN, error: error });
        // not error error.message
        notification.error({
            placement: 'topRight',
            // message: err.message,
            message: 'Wrong Password or Email',
            maxCount: '1',
            duration: '2',
        });
    }
}
