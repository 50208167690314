import types from '../types/types';
const initialState = {
    status: false,
    message: null,
    error: null,
    data: null,
    loading: true,
};

const requestState = {
    ...initialState,
    loading: true,
};
const get_template = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_TEMPLATE_REQUEST:
            return {
                ...state,
                ...requestState,
            };
        case types.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                status: true,
                message: 'SUCCESS',
                error: null,
                data: action.payload,
                loading: false,
            };
        case types.GET_TEMPLATE_FAILURE:
            return {
                ...state,
                status: action.error.status,
                message: action.error.message,
                error: action.error,
                data: null,
                loading: false,
            };

        default:
            return state;
    }
};

export default get_template;
