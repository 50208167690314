import { takeLatest, put } from 'redux-saga/effects';
import types from '../types/types';
import { api_urls } from '../lib/urls';
import Api from '../lib/request-service';
import { view } from '../../analytics/events';

export default function* getCampaignByDomain() {
    yield takeLatest(types.GET_CAMPAIGN_REQUEST, getCampaignByDomainApi);
}
function* getCampaignByDomainApi(params) {
    const name = params.payload;

    try {
        const response = yield Api.get(
            name.includes('preview-') ? api_urls.GET_TEMP_CAMPAIGN_BY_DOMAIN + `/${name}` : api_urls.GET_CAMPAIGN_BY_DOMAIN + `/${name}`,
            true
        );
        if (response.status) {
            yield put({ type: types.GET_CAMPAIGN_SUCCESS, payload: response?.data });
            let title = 'Fanweave';
            if (response?.data) {
                document.title = title + ` | ${response?.data?.name[0].toUpperCase() + response?.data?.name.slice(1)}`;

                if (response?.data?.template?.icon) {
                    var link = document.querySelector("link[rel~='icon']");
                    if (!link) {
                        link = document.createElement('link');
                        link.rel = 'icon';
                        document.head.appendChild(link);
                    }
                    link.href = response?.data?.template?.icon;
                } else {
                    var link = document.querySelector("link[rel~='icon']");
                    if (!link) {
                        link = document.createElement('link');
                        link.rel = 'icon';
                        document.head.appendChild(link);
                    }
                    link.href = response?.data?.template?.logo;
                }
            } else {
                document.title = title;
            }
            view(response?.data);
            // notification success
        } else {
            yield put({ type: types.GET_CAMPAIGN_FAILURE, error: response });
            // notification error res.message
        }
    } catch (error) {
        yield put({ type: types.GET_CAMPAIGN_FAILURE, error: error });
        // notification error error.message
    }
}
